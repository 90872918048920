// ['admin', 'developer', 'manager', 'accountant', 'staff']

export const userRoles = [
  {
    value: "admin",
    label: "Administrator",
    color: "orange",
  },
  {
    value: "developer",
    label: "Dezvoltator",
    color: "cyan",
  },
  {
    value: "manager",
    label: "Manager",
    color: "pink",
  },
  {
    value: "accountant",
    label: "Contabil",
    color: "purple",
  },
  {
    value: "staff",
    label: "Angajat",
    color: "green",
  }
]

export const userFunctions = [
  {
    value: "undefined",
    label: "Nespecificat",
    plural: "Nespecificat",
    description: "Nespecificat"
  },
  {
    value: "intern",
    label: "Stagiar",
    plural: "Stagiari",
    description: "Stagiarul este persoana care se află în perioada de pregătire și formare într-un restaurant, cafenea, bar sau alt local public. Stagiarul este responsabil de învățarea meseriei, de asistarea colegilor și de respectarea regulilor de igienă și siguranță la locul de muncă."
  },
  {
    value: "waiter",
    label: "Ospătar",
    plural: "Ospătari",
    description: "Ospătarul este persoana care se ocupă de servirea clienților într-un restaurant, cafenea, bar sau alt local public. Ospătarul este responsabil de preluarea comenzilor, servirea mâncării și a băuturilor, încasarea banilor și curățarea meselor după ce clienții au terminat de mâncat."
  },
  {
    value: "barman",
    label: "Barman",
    plural: "Barmani",
    description: "Barmanul este persoana care se ocupă de pregătirea și servirea băuturilor alcoolice și nealcoolice într-un restaurant, cafenea, bar sau alt local public. Barmanul este responsabil de pregătirea cocktailurilor, de servirea băuturilor, de încasarea banilor și de menținerea standardelor de calitate și igienă."
  },
  {
    value: "superior_waiter",
    label: "Chelner superior",
    plural: "Chelneri superiori",
    description: "Chelnerul superior este persoana care se ocupă de coordonarea activității ospătarilor într-un restaurant, cafenea, bar sau alt local public. Chelnerul superior este responsabil de preluarea comenzilor, servirea mâncării și a băuturilor, încasarea banilor și curățarea meselor după ce clienții au terminat de mâncat."
  },
  {
    value: "cook",
    label: "Bucătar",
    plural: "Bucătari",
    description: "Bucătarul este persoana care se ocupă de pregătirea mâncărurilor într-un restaurant, cafenea, bar sau alt local public. Bucătarul este responsabil de pregătirea ingredientelor, gătirea mâncării, prezentarea acesteia și curățarea bucătăriei după ce a terminat de gătit."
  },
  {
    value: "sous_chef",
    label: "Sous Chef",
    plural: "Sous Chefi",
    description: "Sous Chef-ul este persoana care se ocupă de coordonarea activității bucătarilor într-un restaurant, cafenea, bar sau alt local public. Sous Chef-ul este responsabil de meniul localului, de pregătirea mâncărurilor, de organizarea echipei de bucătari și de menținerea standardelor de calitate și igienă."
  },
  {
    value: "chef",
    label: "Bucătar chef",
    plural: "Bucătari chef",
    description: "Bucătarul chef este persoana responsabilă de întreaga activitate din bucătăria unui restaurant, cafenea, bar sau alt local public. Bucătarul chef este responsabil de meniul localului, de pregătirea mâncărurilor, de organizarea echipei de bucătari și de menținerea standardelor de calitate și igienă."
  },
  {
    value: "hall_manager",
    label: "Șef sală", 
    plural: "Șefi sală",
    description: "Șeful de sală este persoana responsabilă de întreaga activitate din sala de mese a unui restaurant, cafenea, bar sau alt local public. Șeful de sală este responsabil de organizarea meselor, de preluarea comenzilor, de servirea clienților, de încasarea banilor și de menținerea standardelor de calitate și igienă."
  },
  {
    value: "cleaning",
    label: "Doamnă de serviciu",
    plural: "Doamne de serviciu",
    description: "Doamna de serviciu este persoana responsabilă de curățenia dintr-un restaurant, cafenea, bar sau alt local public. Doamna de serviciu este responsabilă de curățarea meselor, de curățarea podelei, de curățarea toaletelor și de menținerea curățeniei în toate spațiile publice."
  },
  {
    value: "gardener",
    label: "Gradinar",
    plural: "Gradinari",
    description: "Grădinarul este persoana responsabilă de întreținerea spațiilor verzi dintr-un restaurant, cafenea, bar sau alt local public. Grădinarul este responsabil de plantarea florilor, a arbuștilor și a copacilor, de tunderea gazonului, de udatul plantelor și de menținerea curățeniei în spațiile verzi."
  },
  {
    value: "security_agent",
    label: "Agent de pază",
    plural: "Agenți de pază",
    description: "Agentul de pază este persoana responsabilă de securitatea unui restaurant, cafenea, bar sau alt local public. Agentul de pază este responsabil de verificarea accesului persoanelor, de prevenirea furturilor, de intervenția în caz de incidente și de menținerea ordinii și siguranței în local."
  },
  {
    value: "driver",
    label: "Sofer",
    plural: "Soferi",
    description: "Șoferul este persoana responsabilă de transportul mărfurilor și a persoanelor într-un restaurant, cafenea, bar sau alt local public. Șoferul este responsabil de livrarea mărfurilor, de transportul angajaților și de menținerea autovehiculului în stare bună de funcționare."
  },
  {
    value: "engineer",
    label: "Inginer",
    plural: "Ingineri",
    description: "Inginerul este persoana responsabilă de întreținerea instalațiilor tehnice dintr-un restaurant, cafenea, bar sau alt local public. Inginerul este responsabil de repararea instalațiilor electrice, depanarea instalațiilor de climatizare, de întreținerea instalațiilor de apă și de menținerea standardelor de siguranță."
  },
  {
    value: "sales_manager",
    label: "Manager de vânzări",
    plural: "Manageri de vânzări",
    description: "Managerul de vânzări este persoana responsabilă de promovarea și vânzarea produselor și serviciilor unui restaurant, cafenea, bar sau alt local public. Managerul de vânzări este responsabil de identificarea clienților, de prezentarea ofertelor, de încheierea contractelor și de menținerea relațiilor cu clienții."
  },
  {
    value: "event_manager",
    label: "Manager de evenimente",
    plural: "Manageri de evenimente",
    description: "Managerul de evenimente este persoana responsabilă de organizarea evenimentelor dintr-un restaurant, cafenea, bar sau alt local public. Managerul de evenimente este responsabil de planificarea evenimentelor, de coordonarea echipei de organizare, de gestionarea relațiilor cu clienții și de menținerea standardelor de calitate și igienă."
  },
  {
    value: "hotel_manager",
    label: "Hotel Manager",
    plural: "Hotel Manageri",
    description: "Hotel Managerul este persoana responsabilă de întreaga activitate a unui hotel. Hotel Managerul este responsabil de gestionarea resurselor umane, de gestionarea financiară, de gestionarea relațiilor cu clienții, de gestionarea stocurilor, de gestionarea activităților de cazare și de menținerea standardelor de calitate și igienă."
  },
  {
    value: "social_media",
    label: "Social Media Manager",
    plural: "Social Media Manageri",
    description: "Social Media Managerul este persoana responsabilă de promovarea unui restaurant, cafenea, bar sau alt local public pe rețelele de socializare. Social Media Managerul este responsabil de crearea de conținut, de gestionarea paginilor de social media, de interacțiunea cu clienții și de menținerea imaginii brandului."
  },
  {
    value: "boxcatering_operator_junior",
    label: "Boxcatering Operator Junior",
    plural: "Boxcatering Operatori Junior",
    description: "Boxcatering Operatorul Junior este persoana responsabilă de pregătirea și ambalarea cutiilor de mâncare pentru livrare. Boxcatering Operatorul Junior este responsabil de pregătirea ingredientelor, de asamblarea cutiilor, de etichetarea acestora și de pregătirea lor pentru livrare."
  },
  {
    value: "boxcatering_operator_senior",
    label: "Boxcatering Operator Senior",
    plural: "Boxcatering Operatori Senior",
    description: "Boxcatering Operatorul Senior este persoana responsabilă de coordonarea activității Boxcatering Operatorilor Junior. Boxcatering Operatorul Senior este responsabil de pregătirea ingredientelor, de asamblarea cutiilor, de etichetarea acestora, de pregătirea lor pentru livrare și de distribuirea lor către clienți."
  },
  {
    value: "boxcatering_manager",
    label: "Boxcatering Manager",
    plural: "Boxcatering Manageri",
    description: "Boxcatering Managerul este persoana responsabilă de întreaga activitate a unui serviciu de boxcatering. Boxcatering Managerul este responsabil de meniul serviciului, de coordonarea echipei de Boxcatering Operatori, de gestionarea comenzilor și de menținerea standardelor de calitate și igienă."
  },
  {
    value: "acquisitions",
    label: "Achiziții",
    plural: "Achiziții",
    description: "Achizițiile sunt operațiunile de cumpărare a mărfurilor și a serviciilor necesare desfășurării activității unui restaurant, cafenea, bar sau alt local public. Achizițiile sunt responsabile de identificarea furnizorilor, de negocierea prețurilor, de plasarea comenzilor și de menținerea relațiilor cu furnizorii."
  },
  {
    value: "accountant",
    label: "Contabil",
    plural: "Contabili",
    description: "Contabilul este persoana responsabilă de gestionarea financiară a unui restaurant, cafenea, bar sau alt local public. Contabilul este responsabil de întocmirea documentelor financiare, de întocmirea rapoartelor contabile, de întocmirea declarațiilor fiscale și de menținerea standardelor de calitate și igienă."
  },
  {
    value: "admin",
    label: "Administrator",
    plural: "Administratori",
    description: "Administratorul este persoana responsabilă de gestionarea activității unui restaurant, cafenea, bar sau alt local public. Administratorul este responsabil de gestionarea resurselor umane, de gestionarea financiară, de gestionarea stocurilor, de gestionarea relațiilor cu clienții și de menținerea standardelor de calitate și igienă."
  },
]

export const userRolesDict = (userRoles as any).reduce((acc, curr) => {
  acc[curr.value] = {
    ...curr,
  }
  return acc
}, {})

export const userFunctionsDict = (userFunctions as any).reduce((acc, curr) => {
  acc[curr.value] = {
    ...curr,
  }
  return acc
}, {})

